import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Host from './Host';
import axios from 'axios';

import './assets/styles/app.scss';

import thonk from './assets/img/thonk-media-w.svg';
import puff from './assets/img/puff.svg';

interface HostModel {
  emoji: string,
  live: boolean,
  location: string,
  ping: number,
  provider: string
  name: string
}

function App() {
  const [loading, setLoading] = useState(true);
  const [hosts, setHosts] = useState<HostModel[]>([{
    emoji: "❌",
    live: false,
    location: "",
    ping: -1,
    provider: "",
    name: "Error"
  }]);

  useEffect(() => {
    const fetchData = async () =>{
      setLoading(true);
      axios.get("https://api.thonk.xyz/status")
      .then((res) => {
        console.log(res.data);
        setHosts(res.data);
      })
      .catch((error) => {
        console.log(error);
      });

      await delay(1000);

      setLoading(false);
    }

    fetchData();
  }, []);

  return (
    <div className="status">
      <div className="title">
        <a href="https://thonk.xyz" target="_blank" rel="noreferrer">
          <img id="thonk" src={thonk} alt="Thonk Republic Logo"/>
        </a>
        <div id="subtitle">GLOBAL STATUSPAGE</div>
      </div>
      <img id="puff-mobile" src={puff} alt="Ping logo"/>
      <div className="vl">
        <img id="puff" src={puff} alt="Ping logo"/>
      </div>
      <div className="hosts">
        <ul>
          {loading ?
            [...Array(5)].map((e, i) =>
              <li>
                <div className="skeleton">
                  <div id="flag">
                    <Skeleton width={45} height={40}/>
                  </div>

                  <div id="text">
                    <Skeleton width={250} height={24}/>
                    <Skeleton width={160} height={17}/>
                    <Skeleton width={140} height={17}/>
                  </div>
                </div>
              </li>
            )
          :
            hosts.map((h, i) =>
              <li>
                <Host
                  name={h.name}
                  emoji={h.emoji}
                  live={h.live}
                  location={h.location}
                  ping={h.ping}
                  provider={h.provider}
                />
              </li>
            )
          }
        </ul>
      </div>

    </div>
  );
}

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

export default App;
