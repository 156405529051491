import React from 'react';
import './assets/styles/host.scss';

interface HostModel {
    emoji: string,
    live: boolean,
    location: string,
    ping: number,
    provider: string
    name: string
}

function Host(host: HostModel) {
    return (
        <div className="host">
            <div id="flag">
                {host.emoji}
            </div>
            <div className="details">
                <div id="name">
                    {host.name}
                </div>
                <div id="location">
                    {host.location}
                </div>
                <div id="providerping">
                    <span id="ping" className={host.live ? "" : "error"}>
                        {host.live ? Math.round(host.ping) + "ms" :
                        "UNREACHABLE"}
                    </span>

                    <span id="provider">
                        {host.provider}
                    </span>
                </div>

            </div>
        </div>
    )
}

export default Host;